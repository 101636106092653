"use client";

import {useEffect} from "react";

import useUserStore from "@business/components/common/UserModel";

export default function FetchUser() {
    const userStore = useUserStore();

    const fetchUser = async () => {
        try {
            await userStore.fetch();
        } catch (err) {
            /* don't throw error here if not connected */
        }
    };

    useEffect(() => {
        fetchUser();
    }, []);

    return null;
}
