const getAllMethodNames = (obj, depth = Infinity) => {
    const methods = new Set();
    while (depth-- && obj) {
        for (const key of Reflect.ownKeys(obj)) {
            methods.add(key);
        }
        obj = Reflect.getPrototypeOf(obj);
    }
    return [...methods];
};

const forAllMethodNames = (obj, fn) => {
    getAllMethodNames(obj).forEach((method) => fn(method));
};

const getPropertyDescriptor = (object, method) => {
    let descriptor;
    let prototype = Object.getPrototypeOf(object);
    while (prototype && !descriptor) {
        descriptor = Object.getOwnPropertyDescriptor(prototype, method);
        prototype = Object.getPrototypeOf(prototype);
    }
    return descriptor;
};

const toLiteralProp = (source, destination, method, isCollection) => {
    const descriptor = getPropertyDescriptor(source, method);
    if (descriptor && (descriptor.get || descriptor.set)) {
        Object.defineProperty(destination, method, {
            get: descriptor.get,
            set: descriptor.set,
        });
    } else if (!isCollection && method === "computed") {
        // keep computed at collection's root, so each model is created with it
        const computed = source[method]();
        Object.keys(computed).forEach((name) => {
            destination[name] = computed[name];
        });
    } else {
        destination[method] = source[method];
    }
};

export const toLiteral = (model) => {
    const literal = {};
    forAllMethodNames(model, (method) => toLiteralProp(model, literal, method));
    return literal;
};

export const toArrayLiteral = (collection) => {
    const literal = {models: []};
    forAllMethodNames(collection, (method) => toLiteralProp(collection, literal, method, true));
    return literal;
};
