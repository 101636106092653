"use client";

import React from "react";
import classes from "classnames";
import NanoEvents from "nanoevents";

import Wordings from "./Wordings";

const emitter = new NanoEvents();

class Message extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            success: false,
            title: "",
            message: "",
        };
    }
    componentDidMount() {
        this.unbind = emitter.on("toast", (data) => {
            this.setState({
                success: data.success || false,
                title: data.title,
                message: data.message,
                visible: true,
                delay: data.delay || 5000,
            });
        });
    }
    componentDidUpdate() {
        this.clear();
        if (this.state.visible) {
            this.timeout = setTimeout(() => this.close(), this.state.delay);
        }
    }
    componentWillUnmount() {
        this.clear();
        this.unbind();
    }
    close() {
        clearTimeout(this.timeout);
        this.setState({visible: false});
    }
    clear() {
        clearTimeout(this.timeout);
    }
    render() {
        return (
            <div
                className={classes("notification", {
                    "is-invisible": !this.state.visible,
                    "is-success": this.state.success,
                    "is-danger": !this.state.success,
                })}
                data-cy="notification"
            >
                <button className="delete" onClick={this.close.bind(this)}></button>
                {this.state.title ? (
                    <span>
                        <strong>{this.state.title}</strong>
                        <br />
                    </span>
                ) : null}
                <p className="p-display-linebreak">{this.state.message}</p>
            </div>
        );
    }
}

export default Message;

export const toast = (data) => {
    emitter.emit("toast", data);
};

export const wordings = Wordings;
