"use client";

import React, {createContext, useContext, useEffect, useMemo, useState, Dispatch, SetStateAction} from "react";

const MOBILE_WIDTH = 768;
const TABLET_WIDTH = 1023;

type ViewportContext = {
    isReady: boolean;
    width?: number;
    height?: number;
    isMobile?: boolean;
    isTablet?: boolean;
    isTouch?: boolean;
    isDesktop?: boolean;
    navbarHeight?: number;
    setNavbarHeight: Dispatch<SetStateAction<number>>;
};

const ViewportContext = createContext<ViewportContext>({
    isReady: false,
    width: undefined,
    height: undefined,
    isMobile: undefined,
    isTablet: undefined,
    isTouch: undefined,
    isDesktop: undefined,
    navbarHeight: undefined,
    setNavbarHeight: () => 0,
});
ViewportContext.displayName = "ViewportContext";

export const useViewport = () => {
    const context = useContext(ViewportContext);
    if (context === undefined) {
        throw new Error("useViewport must be used within an ViewportProvider");
    }
    return context;
};

const checkIsMobile = (width: number) => width <= MOBILE_WIDTH;
const checkIsTablet = (width: number) => width > MOBILE_WIDTH;
const checkIsTouch = (width: number) => width <= TABLET_WIDTH;
const checkIsDesktop = (width: number) => width > TABLET_WIDTH;

const ViewportProvider = ({children}: any) => {
    const [isReady, setIsReady] = useState(false);
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [isMobile, setIsMobile] = useState(false);
    const [isTablet, setIsTablet] = useState(false);
    const [isTouch, setIsTouch] = useState(false);
    const [isDesktop, setIsDesktop] = useState(false);
    const [navbarHeight, setNavbarHeight] = useState(0);

    const viewportObject = useMemo(() => {
        return {isReady, width, height, isMobile, isTablet, isTouch, isDesktop, navbarHeight, setNavbarHeight};
    }, [isReady, width, height, isMobile, isTablet, isTouch, isDesktop, navbarHeight, setNavbarHeight]);

    const handleWindowResize = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
        setIsMobile(checkIsMobile(window.innerWidth));
        setIsTablet(checkIsTablet(window.innerWidth));
        setIsTouch(checkIsTouch(window.innerWidth));
        setIsDesktop(checkIsDesktop(window.innerWidth));
        setIsReady(true);
    };

    useEffect(() => {
        handleWindowResize();
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    return <ViewportContext.Provider value={viewportObject}>{children}</ViewportContext.Provider>;
};

export default ViewportProvider;
