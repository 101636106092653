"use client";
/* eslint-disable react/react-in-jsx-scope, react/no-unescaped-entities */
import Script from "next/script";

export default function Scripts() {
    return (
        <>
            {process.env.NEXT_PUBLIC_ZENDESK && (
                <>
                    <Script
                        id="ze-snippet"
                        strategy="lazyOnload"
                        src={`https://static.zdassets.com/ekr/snippet.js?key=${process.env.NEXT_PUBLIC_ZENDESK}`}
                        onLoad={() => {
                            // eslint-disable-next-line no-undef
                            zE("messenger:set", "zIndex", 200);
                        }}
                    ></Script>
                </>
            )}
        </>
    );
}
