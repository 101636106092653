import _ from "../Reference";
import addDays from "date-fns/add_days";

const wordings = {
    update: {
        title: "Tout baigne",
        message: "Modifications enregistrées",
        success: true,
    },
    welcome: {
        title: "Bienvenue",
        message: "Heureux de vous compter parmi nous",
        success: true,
    },
    unsubscribe: {
        title: "Tout baigne",
        message: "Formule résiliée avec succès.\n\nOn espère vous revoir bientôt",
        success: true,
    },
    unsubscribe_late: (subscription) => ({
        title: "Tout baigne",
        // eslint-disable-next-line max-len
        message: `Formule résiliée avec succès.\n\nOn espère vous revoir bientôt\n\nVotre dernier Casier est prévu chez ${
            subscription.late.place.name
        }, dans moins de ${
            _.isPlaceShippedNextDay(subscription.late.place) ? "quatre" : "trois"
        } jours. Il ne peut être annulé, la pêche est déjà réservée. Ce Casier est maintenu.\n\nEn cas de gros oubli ou de force majeure, contactez-nous sur sos@poiscaille.fr.`,
        success: true,
        delay: 15000,
    }),
    invit: {
        title: "C’est parti !",
        message: "Un email frétille dans la boîte mail de votre futur filleul (on croise les doigts)",
        success: true,
    },
    password_reset: {
        title: "Tout baigne",
        message: "On a bien enregistré votre nouveau mot de passe",
        success: true,
    },
    password_lost: {
        title: "Message envoyé",
        message: "Un email frétille dans votre boîte mail",
        success: true,
    },
    contact: {
        title: "Message envoyé",
        message: "On vous répond dès que possible",
        success: true,
    },
    allergies: {
        title: "C’est bien noté",
        message: "On fera attention à ces nouvelles allergies",
        success: true,
    },
    shop_sale_delete: {
        title: "Tout baigne",
        message: "Achat résilié avec succès",
        success: true,
    },
    pluses_delete: {
        title: "Tout baigne, p'tit plus annulé",
        message: "Les produits ont bien été retirés de votre Casier",
        success: true,
    },
    code: {
        title: "Code promo validé",
        message:
            "Votre code a bien été pris en compte ! Il sera automatiquement appliqué le matin de votre premier Casier.",
        success: true,
        delay: 10000,
    },
    code_next_step: {
        title: "Code promo validé",
        message: "Votre code promo a bien été appliqué ! Il sera visible lors de la validation de votre abonnement.",
        success: true,
        delay: 10000,
    },
    code_next_step_donation: {
        title: "Code validé",
        message: "Votre don sera bien pris en compte dès la confirmation de votre abonnement au Casier de la mer.",
        success: true,
        delay: 10000,
    },
    pause: (direction, subscription) => {
        const formatted = _.format(
            addDays(
                subscription.late ? subscription.delivery.and : subscription.delivery.at,
                subscription.place.shipping.delay,
            ),
            "dddd D MMMM",
        );
        const forwardMessage = subscription.late
            ? `Votre abonnement a bien été mis en pause. Après réception de votre prochain Casier, votre abonnement reprendra le ${formatted}`
            : `Votre abonnement a bien été mis en pause. Reprise prévue pour le ${formatted} .`;

        const message = direction === "forward" ? forwardMessage : `Votre Casier a bien été rapproché au ${formatted}`;

        return {
            title: "Tout baigne",
            message,
            success: true,
            delay: 10000,
        };
    },
    issue_retry: {
        title: "Vous naviguez en eaux troubles",
        message: "Merci de réessayer",
    },

    issue_password_mismatch: {
        title: "Vous naviguez en eaux troubles",
        message: "Les deux mots de passe saisis sont différents, on ne sait pas lequel utiliser",
    },
    issue_password_reset: {
        title: "Vous naviguez en eaux troubles",
        message: "On dirait que vous n’êtes pas inscrit(e) chez nous, vous avez peut être renseigné un email incorrect",
    },
    issue_subscription_id: {
        title: "Cet abonnement a été déplacé",
        message: "Merci de vous rendre dans « mon compte », vous devriez le retrouver",
        delay: 10000,
    },
    issue_subscription_rate_missing: {
        title: "Informations manquantes",
        message: "Merci de bien sélectionner votre fréquence pour pouvoir démarrer l'aventure",
    },
    issue_subscription_quantity_missing: {
        title: "Informations manquantes",
        message: "Merci de bien renseigner le nombre de Casier pouvoir démarrer l'aventure",
    },
    issue_subscription_place_missing: {
        title: "Informations manquantes",
        message: "Merci de bien sélectionner votre point de livraison pour pouvoir démarrer l'aventure",
    },
    issue_locker: {
        title: "Rupture de stock",
        message: "Désolé, cette combinaison a été victime de son succès, veuillez en sélectionner une autre",
        delay: 10000,
    },
    issue_locker_full: {
        title: "Casier(s) complet(s)",
        message:
            "Vous avez déjà sélectionné tous vos Casiers. Si vous souhaitez en réserver un autre, vous devez en retirer un déjà sélectionné.",
    },
    issue_locker_partial: {
        title: "Casier incomplet",
        message:
            "Vous devez réserver toutes vos combinaisons d'un coup. Ajoutez-en davantage (en cliquant sur +, au pied de chaque combinaison)",
    },
    issue_shop_sale: {
        title: "Vous arrivez trop tard...",
        message:
            "L’heure limite de commande est dépassée pour la date de livraison que vous avez sélectionnée. Veuillez sélectionner un autre jour de livraison.",
        delay: 10000,
    },
    issue_shop_booking: {
        title: "La boutique est fermée",
        message:
            "Pour la date de livraison que vous avez sélectionnée, l’heure de commande est dépassée. Veuillez sélectionner un autre jour.",
        delay: 10000,
    },
    issue_shop_sale_delete: {
        title: "Commande non annulable",
        message: "Votre commande est déjà en cours de préparation, il n’est plus possible de l’annuler.",
    },
    issue_signup: {
        title: "Vous naviguez en eaux troubles",
        message:
            "L'email saisi existe peut-être déjà (si vous avez déjà un compte, connectez-vous via l’espace abonné)",
    },
    issue_signin: {
        title: "Vous naviguez en eaux troubles",
        message: "Soit vous n’avez pas de compte chez nous, soit vous avez saisi un mauvais mot de passe.",
    },
    issue_form_invalid: {
        title: "Formulaire non valide",
        message: "Certaines données saisies sont incorrectes (indiquées en rouge), merci de les saisir à nouveau",
    },
    issue_email_invalid: {
        title: "Oups, petit soucis technique",
        message: "Il nous faut votre email pour valider ce nouveau mot de passe",
    },
    issue_password_lost: {
        title: "Email non valide",
        message:
            "On dirait que vous n’êtes pas inscrit(e) chez nous, vous avez peut être renseigné un email incorrect.",
    },
    issue_geolocation_failed: {
        title: "Échec de localisation",
        message:
            "Désolé matelot, nous n’avons pas pu vous localiser précisément. Veuillez entrer directement une ville ou un code postal dans le champs de recherche.",
    },
    issue_map_search_failed: (search) => ({
        title: "Vous naviguez en eaux troubles",
        message: `Aucun résultat trouvé pour “${search}”. Veuillez saisir une ville ou un code postal valide (situé en France continentale).`,
    }),
    issue_mapboxgl_not_supported: {
        title: "Le bateau prend l’eau…",
        message: "Votre navigateur n'est pas en mesure d'afficher la carte.",
    },
    issue_map_places_fetch_failure: {
        title: "Le bateau prend l’eau…",
        message:
            "Un problème technique nous empêche d'afficher la carte des points relais. On vous invite à réessayer ultérieurement",
    },
    issue_address_unknown: {
        title: "Vous naviguez en eaux troubles",
        message:
            "Aucun point relais disponible à votre adresse pour le jour sélectionné. Veuillez renseigner une autre adresse, filtrer sur un autre jour de livraison ou opter pour la livraison à domicile.",
        delay: 10000,
    },
    issue_address_only_home: {
        title: "Livraison à domicile uniquement",
        // eslint-disable-next-line max-len
        message:
            "Aucun point relais disponible à votre adresse pour le jour sélectionné. Veuillez renseigner une autre adresse, filtrer sur un autre jour de livraison ou opter pour la livraison à domicile.",
        delay: 10000,
    },
    issue_address_to_close: {
        title: "Livraison en région parisienne uniquement",
        message: "Malheureusement, nous ne sommes pas en mesure de livrer demain hors de la région parisienne.",
        delay: 10000,
    },
    issue_address_closing: {
        title: "Lieu non livré",
        message: "Malheureusement, ce lieu n'est pas livré à cette date.",
        delay: 10000,
    },
    issue_address_not_delivered_on: {
        title: "Lieu non livré ce jour",
        message:
            "Malheureusement, ce lieu n'est pas livré à cette date — pour récupérer ce produit, vous devez choisir un point relais livré à cette date.",
        delay: 10000,
    },
    issue_address_not_delivered: {
        title: "Livraison non disponible",
        message:
            "Malheureusement, nous n'avons aucune solution de livraison à vous proposer à cette adresse pour l'instant.",
        delay: 10000,
    },
    issue_already_chosen: {
        title: "Vous naviguez en eaux troubles",
        message: "Casier déjà choisi pour une date future",
    },
    issue_to_late: {
        title: "Commande trop tardive",
        message: "Malheureusement, les réservations pour ce jour sont terminées.",
        delay: 10000,
    },
    issue_invit: {
        title: "Vous naviguez en eaux troubles",
        message: "Votre proche est déjà embarqué, vous ne pouvez pas le parrainer !",
    },
    issue_no_formula: {
        title: "Vous naviguez en eaux troubles",
        message: "Vous ne pouvez pas parrainer un proche, si vous n'êtes pas abonné vous-même",
    },
    issue_no_agreement: {
        title: "Vous naviguez en eaux troubles",
        message:
            "Vous devez accepter les conditions générales de vente pour continuer (case à cocher située pile sous le bouton principal)",
    },
    issue_newsletter_no_agreement: {
        title: "Vous naviguez en eaux troubles",
        message:
            "Vous devez accepter de recevoir du contenu sur la pêche durable pour continuer (case à cocher située au-dessus du bouton d'envoi)",
    },
    issue_pause_upcoming: {
        title: "Mise en pause impossible",
        message: "Vous ne pouvez pas mettre en pause cet abonnement pour l'instant",
    },
    issue_technical: (message) => {
        return {
            title: "Vous naviguez en eaux troubles",
            message,
        };
    },
    csat_saved: {
        title: "Merci, votre avis est précieux pour nous !",
        success: true,
    },
    csat_issue_save_error: {
        title: "Nous n'arrivons pas à sauvegarder votre avis",
        message:
            "Un problème technique nous empêche de sauvagarder votre avis. On vous invite à réessayer ultérieurement",
    },
    csat_issue_already_filled: {
        title: "L’avis sur ce Casier a déjà été renseigné",
        message:
            "Si un détail vous est revenu et que vous souhaitez nous en dire plus, n’hésitez pas à contacter notre service client à l’adresse sos@poiscaille.fr",
    },
    csat_issue_too_late: {
        title: "Désolé matelot vous ne pouvez plus renseigner d’avis sur ce Casier",
        message:
            "Si un détail vous est revenu et que vous souhaitez nous en dire plus, n’hésitez pas à contacter notre service client à l’adresse sos@poiscaille.fr",
    },
    sale_late_notification_issue_already_notified: (fullName) => ({
        title: "Ohé du bateau !",
        message: `${fullName} a déjà reçu un sms pour cette commande.`,
    }),
    sale_late_notification_issue_technical: {
        title: "Le bateau prend l’eau…",
        message: "Un problème technique a eu lieu. Veuillez réessayer ultérieurement.",
    },
    sale_late_notification_success: (fullName) => ({
        success: true,
        title: "C’est tout bon !",
        message: `Un SMS a été envoyé à ${fullName} avec succès.`,
    }),
    form_submit_success: {
        title: "Tout baigne",
        message: "Votre demande a bien été transmise",
        success: true,
    },
    form_submit_failure: {
        title: "Le bateau prend l’eau…",
        message: "Un problème technique a eu lieu. Veuillez réessayer ultérieurement.",
    },
    newsletter_form_submit_success: {
        title: "Merci matelot",
        message: "Rendez-vous dans votre boîte mail pour confirmer votre inscription.",
        success: true,
    },
    newsletter_form_submit_failure: {
        title: "Le bateau prend l’eau…",
        message:
            "Nous n'avons pas pu vous inscrire à cause d'un problème technique. Veuillez réessayer ultérieurement.",
    },
};

export default wordings;
