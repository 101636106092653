import Model, {create, toLiteral} from "./http/Model";
import {tracker} from "./Tracker";

const poiscailleApiUrl = `${process.env.NEXT_PUBLIC_POISCAILLE_API_URL}`;
class UserModel extends Model {
    url() {
        return `${poiscailleApiUrl}/user`;
    }
    props() {
        return {
            id: String,
            email: String,
            password: String,
            passwordConfirm: String,
            firstName: String,
            lastName: String,
            phone: String,
            customer: String,
            formula: Number,
            last4: String,
            godfather: String,
            godsons: Array,
            roles: Array,
        };
    }
    fetch(options) {
        options = options || {url: `${poiscailleApiUrl}/user`};
        return super
            .fetch({}, options)
            .then((user) => this._trackAndContinue(user.toJSON()))
            .catch((err) => {
                this._trackAndContinue();
                throw err;
            });
    }
    save(data, options) {
        options = options || {url: `${poiscailleApiUrl}/user`};
        return super.save(data, options);
    }
    signin(data) {
        return this.save(data, {url: `${poiscailleApiUrl}/user/signin`, method: "POST"}).then((user) => {
            return this._trackAndContinue(user.toJSON());
        });
    }
    signup(data) {
        // FIXME refresh user, so card can be edited in dashboard on signup
        return this.save(data, {url: `${poiscailleApiUrl}/user/signup`, method: "POST"}).then((user) =>
            this._trackAndContinue(user.toJSON(), "signup"),
        );
    }
    signout() {
        return this.destroy({url: `${poiscailleApiUrl}/user/signout`}).then((user) => this._untrackAndContinue(user));
    }
    resetPassword(data) {
        return this.save(data, {url: `${poiscailleApiUrl}/user/reset`, method: "POST"});
    }
    lost(data) {
        return this.save(data, {url: `${poiscailleApiUrl}/user/forgot`, method: "POST"});
    }
    reset(data) {
        return this.saveRaw(data, {url: `${poiscailleApiUrl}/user/reset`, method: "POST"});
    }
    invit(data) {
        return this.saveRaw(data, {url: `${poiscailleApiUrl}/user/invit`, method: "POST"});
    }
    isAuthenticated() {
        return !!this.id;
    }
    isSubscribed() {
        return !!this.formula;
    }
    hasRole(role) {
        return Array.isArray(this.roles) && this.roles.includes(role);
    }
    hasExperimentalFeatures(useOddPopulation = false) {
        return useOddPopulation ? !this._isInEvenPopulation() : this._isInEvenPopulation();
    }
    _isInEvenPopulation() {
        return this._getEmailCumulativeCharCode() % 2 === 0;
    }
    _getEmailCumulativeCharCode() {
        return this.email.split("").reduce((memo, char) => memo + char.charCodeAt(0), 0);
    }
    _trackAndContinue(user, type) {
        if (!user) tracker.resetAuthentication();
        else if (type === "signup") tracker.signUp({user});
        else tracker.confirmAuthentication({user});
        return user;
    }
    _untrackAndContinue(user) {
        tracker.signOut();
        return user;
    }
}

export default create((set, get) => toLiteral(new UserModel(set, get)));
